import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import { AccessToken, AccessTokenSettings, BCCAccount, EssAccount, ShareSecret } from 'global/types/api/accessTokenType'
import { generateUserDataLib } from 'global/lib/userData/userData'

import { logout } from 'global/redux/features/auth/authApiThunks'

export interface AccessTokenState {
  isAccessTokenSet: boolean
  accessToken: AccessToken | undefined
  essAccount: EssAccount | undefined
  bccAccount: BCCAccount | undefined
  shareSecret: ShareSecret | undefined
}

// initialState
export const INITIAL_STATE: AccessTokenState = {
  isAccessTokenSet: false,
  accessToken: undefined,
  essAccount: undefined,
  bccAccount: undefined,
  shareSecret: undefined
}

export const setCurrentAccessToken = createAsyncThunk(
  'ACCESS_TOKEN/setCurrentAccessToken',
  async function doSetCurrentAcccessToken(accessTokenId: AccessToken['id'], { getState }) {
    const userDataLib = generateUserDataLib(() => (getState() as any).user)
    const accessToken = userDataLib.getAccessTokenById(accessTokenId)
    const bccID = userDataLib.getAccountByAccessToken(accessTokenId)?.bccId || ''

    return { accessToken, bccID }
  }
)

export const setAccessTokenSecret = createAsyncThunk(
  'ACCESS_TOKEN/setAccessTokenSecret',
  async function doSetAccessTokenSecret(
    { accessTokenId, reportSecret }: { accessTokenId: AccessToken['id']; reportSecret: AccessToken['reportSecret'] },
    { getState }
  ) {
    const userDataLib = generateUserDataLib(() => (getState() as any).user)
    const accessToken = userDataLib.getAccessTokenById(accessTokenId)

    if (accessToken) {
      return {
        ...accessToken,
        reportSecret
      }
    }

    return undefined
  }
)

/* eslint-disable no-param-reassign */
export const accessTokenSlice = createSlice({
  name: 'ACCCESS_TOKEN',
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentBccAccount: {
      reducer: (state: AccessTokenState, action: PayloadAction<{ bccAccount: BCCAccount }>) => {
        state.bccAccount = action.payload.bccAccount
      },
      prepare: (bccAccount: BCCAccount) => ({ payload: { bccAccount } })
    },

    setCurrentEssAccount: {
      reducer: (state: AccessTokenState, action: PayloadAction<{ essAccount: EssAccount }>) => {
        state.essAccount = action.payload.essAccount
      },
      prepare: (essAccount: EssAccount) => ({ payload: { essAccount } })
    },

    setShareSecret: {
      reducer: (state: AccessTokenState, action: PayloadAction<{ shareSecret: ShareSecret }>) => {
        state.shareSecret = action.payload.shareSecret
      },
      prepare: (shareSecret: ShareSecret) => ({ payload: { shareSecret } })
    },

    updateCurrentAccessToken: {
      reducer: (state: AccessTokenState, action: PayloadAction<{ accessToken: AccessToken }>) => {
        state.isAccessTokenSet = true
        state.accessToken = action.payload.accessToken
      },
      prepare: (accessToken: AccessToken) => ({ payload: { accessToken } })
    },

    updateCurrentSettings: {
      reducer: (state: AccessTokenState, action: PayloadAction<{ settings: AccessTokenSettings }>) => {
        if (state.accessToken) {
          state.accessToken.settings = { ...state?.accessToken?.settings, ...(action.payload.settings || {}) }
        }
      },
      prepare: (settings: AccessTokenSettings) => ({ payload: { settings } })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout.fulfilled, () => {
        return {
          ...INITIAL_STATE
        }
      })
      .addCase(setCurrentAccessToken.pending, state => {
        state.isAccessTokenSet = false
      })
      .addCase(setCurrentAccessToken.fulfilled, (state, action) => {
        state.isAccessTokenSet = true
        state.accessToken = action.payload.accessToken
        state.bccAccount = action.payload.bccID
      })
      .addCase(setAccessTokenSecret.fulfilled, (state, action) => {
        if (action.payload) {
          state.accessToken = action.payload
        }
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  setCurrentBccAccount,
  setCurrentEssAccount,
  setShareSecret,
  updateCurrentAccessToken,
  updateCurrentSettings,
  reset
} = accessTokenSlice.actions

export default accessTokenSlice.reducer
