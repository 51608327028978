import { User } from 'global/types/api/userType'
import { Account } from 'global/types/api/accountType'
import { AccessToken } from 'global/types/api/accessTokenType'

import { reduxStore } from 'global/lib/reduxStore'
import { config } from 'global/lib/config'

export interface UserDataLib {
  hasAccessToken: () => boolean
  hasUser: () => boolean
  isAuthenticated: () => boolean
  isOtpAuthenticated: () => boolean
  isCudaAdmin: () => boolean
  isImpersonationMode: () => boolean
  isMspManagedAccount: (accessTokenId?: string) => boolean
  isUserAdmin: (accessTokenId: string) => boolean
  isNoTokenAccessToken: (accessTokenId: string) => boolean
  isOnPremAccessToken: (accessToken: any) => boolean
  requiresOtpAuthentication: () => boolean
  getUser: () => User
  getAccounts: () => Account[]
  getAccessTokens: () => AccessToken[]
  getAccessTokenById: (id: string) => AccessToken | undefined
  getAccessTokenByIdNoAuth: (accessTokenId: string) => AccessToken | undefined
  getAccessTokenDisplayName: (accessToken: any) => string
  getAccountByAccountId: (accountId?: string) => Account | null
  getAccountByAccessToken: (accessTokenId?: string) => Account | null
  getAccountByBccId: (bccId: string) => Account | null
  getAccountByName: (name: string) => Account | null
  getAccountsLicensedForProduct: (product: string) => Account[]
  getFeaturesForAccessToken: (accessTokenId: string) => string[]
  getProductsForAccessToken: (accessTokenId: string) => string[]
  updateAccessToken: (accessTokenId: string, updateCb: (accessToken: AccessToken) => AccessToken) => Account[]
}

export function generateUserDataLib(userStore: () => any = () => reduxStore.getState().user): UserDataLib {
  /* ***************** *
   * Boolean functions *
   * ***************** */
  function hasAccessToken(): boolean {
    const accessTokens = getAccessTokens()

    return accessTokens && !!accessTokens.length
  }

  function hasUser(): boolean {
    const user = userStore()
    return !!user && !!user.data && !!Object.keys(user.data).length
  }

  function isAuthenticated(): boolean {
    return hasUser() && isOtpAuthenticated()
  }

  function isOtpAuthenticated() {
    const user = userStore()

    if (user.data.mfa) {
      return user.data.isMFAuthenticated
    }

    return true
  }

  function isCudaAdmin(): boolean {
    const user = userStore()

    return isAuthenticated() && user.data.isStaff
  }

  function isImpersonationMode(): boolean {
    return hasUser() && !!getUser().impersonationMode
  }

  function isMspManagedAccount(accessTokenId?: string): boolean {
    return !!(getAccountByAccessToken(accessTokenId) || ({} as Account)).mspManaged
  }

  function isUserAdmin(accessTokenId: string): boolean {
    return !!(getAccountByAccessToken(accessTokenId) || ({} as Account)).userAdminFlag
  }

  function isNoTokenAccessToken(accessTokenId: string): boolean {
    return accessTokenId === config.NO_TOKEN_TOKEN
  }

  function isOnPremAccessToken(accessToken: any): boolean {
    return !!accessToken && accessToken.provider === config.CLOUD_PROVIDERS.onprem.id
  }

  function requiresOtpAuthentication(): boolean {
    const user = userStore()

    return user.data.mfa && !user.data.isMFAuthenticated
  }
  /* ********************* *
   * End boolean functions *
   * ********************* */

  function getUser(): User {
    return (userStore() || {}).data
  }

  function getAccounts(): Account[] {
    if (isAuthenticated()) {
      return userStore().data.accounts
    }

    return []
  }

  function getAccessTokens(): AccessToken[] {
    return getAccounts()
      .reduce((all: AccessToken[], account: Account) => {
        return [...all, ...account.accessTokens]
      }, [])
      .sort((a: AccessToken, b: AccessToken) => new Date(b.created).getTime() - new Date(a.created).getTime())
  }

  function getAccessTokenById(id: string): AccessToken | undefined {
    if (isAuthenticated()) {
      return getAccessTokenByIdNoAuth(id)
    }

    return undefined
  }

  function getAccessTokenByIdNoAuth(accessTokenId: string): AccessToken | undefined {
    return getAccessTokens().find((token: AccessToken) => token.id === accessTokenId)
  }

  function getAccessTokenDisplayName(accessToken: any): string {
    if (isOnPremAccessToken(accessToken)) {
      return config.ON_PREM_DEFAULTS.NAME
    }

    return accessToken ? accessToken.name : config.OFFICE365_DEFAULTS.UNKNOWN
  }

  function getAccountByAccountId(accountId?: string): Account | null {
    return getAccounts().find(account => account.accountId === accountId) || null
  }

  function getAccountByAccessToken(accessTokenId?: string): Account | null {
    return (
      getAccounts().find(account => {
        if (account.accessTokens) {
          return !!account.accessTokens.find((accessToken: AccessToken) => accessToken.id === accessTokenId)
        }

        return false
      }) || null
    )
  }

  function getAccountByBccId(bccId: string): Account | null {
    return getAccounts().find(account => account.bccId === bccId) || null
  }

  function getAccountByName(name: string): Account | null {
    return getAccounts().find(account => account.accountName === name) || null
  }

  function getAccountsLicensedForProduct(product: string): Account[] {
    const accounts = getAccounts()
    if (product === 'fir') {
      return accounts.filter((account: Account) => account.bccSerialNumbers.fir.state === 'A')
    }
    if (product === 'sentinel') {
      return accounts.filter(
        (account: Account) =>
          account.bccSerialNumbers.sentinel.state === 'A' || account.bccSerialNumbers.dfp.state === 'A'
      )
    }
    return []
  }

  function getFeaturesForAccessToken(accessTokenId: string): string[] {
    if (hasUser()) {
      const at = getAccessTokenByIdNoAuth(accessTokenId)
      return at ? at.features : []
    }
    return []
  }

  function getProductsForAccessToken(accessTokenId: string): string[] {
    if (hasUser()) {
      const at = getAccessTokenByIdNoAuth(accessTokenId)
      return at ? at.products : []
    }
    return []
  }

  function updateAccessToken(accessTokenId: string, updateCb: (accessToken: AccessToken) => AccessToken): Account[] {
    return getAccounts().map(account => {
      const updatedAccessTokens = account.accessTokens.map((accessToken: AccessToken) => {
        if (accessToken.id === accessTokenId) {
          return updateCb({ ...accessToken })
        }

        return accessToken
      })

      return {
        ...account,
        accessTokens: updatedAccessTokens
      }
    })
  }

  return {
    // boolean methods
    hasAccessToken,
    hasUser,
    isAuthenticated,
    isOtpAuthenticated,
    isCudaAdmin,
    isImpersonationMode,
    isMspManagedAccount,
    isUserAdmin,
    isNoTokenAccessToken,
    isOnPremAccessToken,
    requiresOtpAuthentication,

    // getter methods
    getUser,
    getAccounts,
    getAccessTokens,
    getAccessTokenById,
    getAccessTokenByIdNoAuth,
    getAccessTokenDisplayName,
    getAccountByAccountId,
    getAccountByAccessToken,
    getAccountByName,
    getAccountByBccId,
    getAccountsLicensedForProduct,
    getFeaturesForAccessToken,
    getProductsForAccessToken,

    // update methods
    updateAccessToken
  }
}

export default generateUserDataLib()
