import React, { useMemo, useCallback, useEffect } from 'react'

import { snakeCase, isEqual } from 'lodash'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

import {
  NavigationBarProps,
  NavigationRoute,
  ProfileAvatar,
  ProfileMenu
} from '@barracuda-internal/bds-core/dist/UnifiedComponents/UnifiedNavigation'
import { BDSProvider, Typography } from '@barracuda-internal/bds-core'
import { FaviconEmail } from '@barracuda-internal/bds-core/dist/Logos/Favicons'
import { BarChart } from '@barracuda-internal/bds-core/dist/Icons/Charts'
import { Email, UserReportedEmails } from '@barracuda-internal/bds-core/dist/Icons/Email'
import {
  User,
  Language,
  Apps,
  ExitToApp,
  OpenInNew,
  RecentActors,
  Settings,
  SupervisorAccount
} from '@barracuda-internal/bds-core/dist/Icons/Core'
import ProductSwitcher from '@barracuda-internal/bds-core/dist/UnifiedComponents/ProductSwitcher/ProductSwitcher'
import {
  ProductDataSubscriptionGroup,
  ProductName
} from '@barracuda-internal/bds-core/dist/UnifiedComponents/ProductSwitcher/types'
import { useProductData } from '@barracuda-internal/bds-core/dist/UnifiedComponents/ProductSwitcher/hooks/useProductData'

import { isPending } from 'global/redux/toolkit/api'
import { Account } from 'global/types/api/accountType'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'
import browserHistory from 'global/lib/routes/browserHistory'
import { config } from 'global/lib/config'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useProductLib from 'global/lib/product/useProduct'
import useUserDataLib from 'global/lib/userData/useUserData'
import { useFormatMessage } from 'global/lib/localization'
import * as datetime from 'global/lib/datetime'
import { createUrlGenerator } from 'global/lib/routes/routesConfig'
import { isAfterDate } from 'global/lib/datetime'
import { logout, refreshToken } from 'global/redux/features/auth/authSlice'
import { setAccountSubscriptionData } from 'global/redux/features/account/accountSlice'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import routesConfig from 'sen/lib/routes/routesConfig'

type Jwt = {
  exp: number
  [key: string]: string | number
}

// TODO: Remove TOKEN_PLACEHOLDER in ticket BNFIR-4224
const TOKEN_PLACEHOLDER = 'TOKEN_PLACEHOLDER'
const FIVE_MINUTES = 5 * 60 * 1000
const BASE_I18N_KEY = 'sen.app.navbar'
const IP_PRODUCT_ID = '20'

export default function useNavbarLogic(): NavigationBarProps[] {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [accessTokenLib] = useAccessTokenLib()
  const [productLib] = useProductLib()
  const [userDataLib] = useUserDataLib()
  const dispatch = useAppDispatch()
  const bccToken = Cookies.get(config.COOKIES.BCC_AT) || ''

  const {
    accessTokenId,
    accountSubscriptionData,
    activePath,
    bccAccount,
    defaultAccountBccId,
    isRefreshTokenPending,
    isSenDemoUser,
    splitStore,
    userBccId,
    userEmail,
    userName
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    accountSubscriptionData: _stores.account.accountSubscriptionData,
    activePath: _stores.app.activePath.url || '',
    bccAccount: _stores.accessToken.bccAccount || '',
    defaultAccountBccId: _stores.user.data?.defaultAccountBccId || '',
    isRefreshTokenPending: isPending(_stores.auth.refreshTokenApiStatus),
    isSenDemoUser: _stores.user.isSenDemoUser,
    splitStore: _stores.splitio,
    userBccId: _stores.user.data?.bccUserId || '',
    userEmail: _stores.user.data?.email,
    userName: _stores.user.data?.displayName || ''
  }))

  const apiToken = bccToken || TOKEN_PLACEHOLDER

  const { data } = useProductData({
    userId: userBccId,
    accountId: bccAccount || defaultAccountBccId,
    apiToken,
    hostname: config.productDataServiceUrl
  })

  // BCC token has 1 hour expiration time, we need to get a new token when old token is expired
  useEffect(() => {
    // demo account and impersonation don't go through login flow curretnly, so skip the token check for them and use the hardcoded IR/ETS
    if (isSenDemoUser || userDataLib.isImpersonationMode()) {
      return
    }

    if (apiToken === TOKEN_PLACEHOLDER && !isRefreshTokenPending) {
      dispatch(refreshToken())

      return
    }

    if (bccToken) {
      const decodedToken = jwtDecode(bccToken) as Jwt
      const tokenExpireTime = new Date(decodedToken.exp * 1000 - FIVE_MINUTES) // Set the token expiration time to 5 minutes earlier, allowing us to get a new token when there are about 5 minutes remaining before it expires.
      const isTokenExpired = isAfterDate({ initialDate: new Date(), subtractedDate: tokenExpireTime })

      if (isTokenExpired) {
        dispatch(refreshToken())
      }
    }
  }, [apiToken, bccToken, dispatch, isRefreshTokenPending, isSenDemoUser, userDataLib])

  useEffect(() => {
    if (data?.subscriptionGroups && !isEqual(data.subscriptionGroups, accountSubscriptionData)) {
      dispatch(setAccountSubscriptionData(data.subscriptionGroups))
    }
  }, [accountSubscriptionData, data, dispatch])

  const isRouteDisabled = useMemo(
    () => activePath === routesConfig.START_TRIAL.path || activePath === routesConfig.CLEANUP_TABLE.path,
    [activePath]
  )

  const accounts = userDataLib.getAccounts()
  const isAdmin = useMemo(() => userDataLib.isUserAdmin(accessTokenId), [accessTokenId, userDataLib])

  // Use the current account Id to select splitio treatment value
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessTokenId)?.accountId, [
    accessTokenId,
    userDataLib
  ])

  const isLicenseComplianceOn = useMemo(() => isMyFeatureOn(splitStore, FEATURES.IP_LICENSING_COMPLIANCE, accountId), [
    accountId,
    splitStore
  ])

  // TODO: remove this function once PAM complete backfill subscription data on PROD
  const isProductInResponse = useCallback(
    (productName: string) => {
      return (accountSubscriptionData as ProductDataSubscriptionGroup[]).some(item =>
        item.subscriptions.some(subscription => subscription.product?.name === productName)
      )
    },
    [accountSubscriptionData]
  )

  // TODO: remove this function once PAM complete backfill subscription data on PROD
  const hasETSInSubscriptionData = useMemo(() => {
    return isProductInResponse(formatMessage('productSwitcher.ets'))
  }, [formatMessage, isProductInResponse])

  // TODO: remove this function once PAM complete backfill subscription data on PROD
  const hasIRInSubscriptionData = useMemo(() => {
    return isProductInResponse(formatMessage('productSwitcher.incidentResponse'))
  }, [formatMessage, isProductInResponse])

  // TODO: remove this function once PAM complete backfill subscription data on PROD
  const isConvergedMessageLogFeatureOn = useMemo(
    () => isMyFeatureOn(splitStore, FEATURES.EGD_IP_IR_CONVERGED_EMAIL_LOG_ROLLOUT, accountId),
    [accountId, splitStore]
  )

  const etsTokens = useMemo(
    () =>
      accounts.flatMap((account: Account) =>
        account.accessTokens
          .filter(accessToken => accessToken.products.includes('ets'))
          .map(accessToken => accessToken.id)
      ),
    [accounts]
  )

  // TODO: remove this function once PAM complete backfill subscription data on PROD
  const ets = useMemo(
    () => ({
      appName: ProductName.EMAIL_THREAT_SCANNER,
      url: `${config.domains.ets}/report/${etsTokens[0]}`
    }),
    [etsTokens]
  )

  // TODO: remove this function once PAM complete backfill subscription data on PROD
  const incidentResponse = useMemo(
    () => ({
      appName: ProductName.INCIDENT_RESPONSE,
      url: `${config.domains.forensics}/report/${accessTokenId}`
    }),
    [accessTokenId]
  )

  const incidentResponsePath = useMemo(() => (accessTokenId ? `report/${accessTokenId}/remediation` : ''), [
    accessTokenId
  ])

  const isLicenseVisible = useMemo(() => {
    return (
      !userDataLib.isMspManagedAccount(accessTokenId) &&
      isLicenseComplianceOn &&
      accessTokenLib.hasSentinelEntitlement(accessTokenId)
    )
  }, [accessTokenId, accessTokenLib, isLicenseComplianceOn, userDataLib])

  const onNavigate = useCallback((path: string) => {
    browserHistory.push(path)
  }, [])

  const generatePath = useCallback(
    (path: string) => {
      const createUrl = createUrlGenerator(path)
      return `${createUrl({ reportId: accessTokenId })}`
    },
    [accessTokenId]
  )

  // TODO remove BUNDLE1_SIDE_MENU_ITEMS once DFP standalone goes live
  const BUNDLE1_SIDE_MENU_ITEMS: NavigationRoute[] = useMemo(
    () => [
      ...(isConvergedMessageLogFeatureOn
        ? [
            {
              path: generatePath(routesConfig.CONVERGED_MESSAGE_LOG.path),
              icon: <Email />,
              name: formatMessage(`${snakeCase(routesConfig.CONVERGED_MESSAGE_LOG.id)}`),
              disabled: isRouteDisabled
            }
          ]
        : []),
      {
        path: generatePath(routesConfig.DASHBOARD.path),
        icon: <UserReportedEmails />,
        name: formatMessage(`${snakeCase(routesConfig.DASHBOARD.id)}`),
        disabled: isRouteDisabled
      },
      {
        path: generatePath(routesConfig.ACCOUNT_TAKEOVER.path),
        icon: <User />,
        name: formatMessage(`${snakeCase(routesConfig.ACCOUNT_TAKEOVER.id)}`),
        disabled: isRouteDisabled
      },

      {
        path: generatePath(routesConfig.UNIFIED_REPORTING_ROOT.path),
        icon: <BarChart />,
        name: formatMessage(`${snakeCase(routesConfig.UNIFIED_REPORTING_ROOT.id)}`),
        disabled: isRouteDisabled,
        children: [
          {
            name: formatMessage(`${snakeCase(routesConfig.REPORT_LIST.id)}`),
            disabled: isRouteDisabled,
            path: generatePath(routesConfig.REPORT_LIST.path)
          },
          {
            name: formatMessage(`${snakeCase(routesConfig.SCHEDULED_REPORTS.id)}`),
            disabled: isRouteDisabled,
            path: generatePath(routesConfig.SCHEDULED_REPORTS.path)
          }
        ]
      },

      {
        path: generatePath(routesConfig.SETTINGS.path),
        icon: <Settings />,
        name: formatMessage(`${snakeCase(routesConfig.SETTINGS.id)}`),
        disabled: isRouteDisabled
      }
    ],
    [generatePath, formatMessage, isRouteDisabled, isConvergedMessageLogFeatureOn]
  )

  const ALL_SIDE_MENU_ITEMS: NavigationRoute[] = useMemo(
    () => [
      ...(isConvergedMessageLogFeatureOn
        ? [
            {
              path: generatePath(routesConfig.CONVERGED_MESSAGE_LOG.path),
              icon: <Email />,
              name: formatMessage(`${snakeCase(routesConfig.CONVERGED_MESSAGE_LOG.id)}`),
              disabled: isRouteDisabled
            }
          ]
        : []),
      {
        path: generatePath(routesConfig.DASHBOARD.path),
        icon: <UserReportedEmails />,
        name: formatMessage(`${snakeCase(routesConfig.DASHBOARD.id)}`),
        disabled: isRouteDisabled
      },
      {
        path: generatePath(routesConfig.ACCOUNT_TAKEOVER.path),
        icon: <User />,
        name: formatMessage(`${snakeCase(routesConfig.ACCOUNT_TAKEOVER.id)}`),
        disabled: isRouteDisabled
      },
      // TODO: remove DOMAIN_FRAUD once DFP standalone goes live
      {
        path: generatePath(routesConfig.DOMAIN_FRAUD.path),
        icon: <Language />,
        name: formatMessage(`${snakeCase(routesConfig.DOMAIN_FRAUD.id)}`),
        disabled: isRouteDisabled
      },
      {
        path: generatePath(routesConfig.UNIFIED_REPORTING_ROOT.path),
        icon: <BarChart />,
        name: formatMessage(`${snakeCase(routesConfig.UNIFIED_REPORTING_ROOT.id)}`),
        disabled: isRouteDisabled,
        children: [
          {
            name: formatMessage(`${snakeCase(routesConfig.REPORT_LIST.id)}`),
            disabled: isRouteDisabled,
            path: generatePath(routesConfig.REPORT_LIST.path)
          },
          {
            name: formatMessage(`${snakeCase(routesConfig.SCHEDULED_REPORTS.id)}`),
            disabled: isRouteDisabled,
            path: generatePath(routesConfig.SCHEDULED_REPORTS.path)
          }
        ]
      },

      {
        path: generatePath(routesConfig.SETTINGS.path),
        icon: <Settings />,
        name: formatMessage(`${snakeCase(routesConfig.SETTINGS.id)}`),
        disabled: isRouteDisabled
      }
    ],
    [generatePath, formatMessage, isRouteDisabled, isConvergedMessageLogFeatureOn]
  )

  const DP_SIDE_MENU_ITEMS = useMemo(
    () => [
      {
        path: generatePath(routesConfig.DOMAIN_FRAUD.path),
        icon: <Language />,
        name: formatMessage(`${snakeCase(routesConfig.DOMAIN_FRAUD.id)}`),
        disabled: isRouteDisabled
      }
    ],
    [generatePath, formatMessage, isRouteDisabled]
  )

  /* With the new PAM V2 activation we need to support bundle backwards compatibility until the DFP standalone is deployed */
  const routes: NavigationRoute[] = useMemo(() => {
    const hasSentinel = productLib.hasSentinelProduct(accessTokenId)
    const hasDFP = productLib.hasDFPProduct(accessTokenId)

    switch (true) {
      case hasSentinel && !hasDFP:
        return BUNDLE1_SIDE_MENU_ITEMS
      case hasSentinel && hasDFP:
        return ALL_SIDE_MENU_ITEMS
      case !hasSentinel && hasDFP:
        return DP_SIDE_MENU_ITEMS
      default:
        return ALL_SIDE_MENU_ITEMS
    }
  }, [ALL_SIDE_MENU_ITEMS, BUNDLE1_SIDE_MENU_ITEMS, DP_SIDE_MENU_ITEMS, accessTokenId, productLib])

  // TODO: This function will be moved to global lib for use across IP, IR, DFP and ETS
  const getInitials = useCallback((name: string) => {
    const names = name.split(' ')
    const initials = names.map((n: string) => n[0]).join('')
    return initials.toUpperCase()
  }, [])

  const onGoToLicense = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_REVIEW_LICENSES)
    routesConfig.LICENSE.goto({
      reportId: accessTokenId
    })
  }, [accessTokenId])

  // start logout
  const onLogout = useCallback(() => {
    dispatch(logout(true))
  }, [dispatch])

  const serialNumber = useMemo(() => productLib.getSentinelSerialNumberForAccessToken(accessTokenId), [
    accessTokenId,
    productLib
  ])

  const expiration = useMemo(() => productLib.getSentinelSerialExpiryForAccessToken(accessTokenId), [
    accessTokenId,
    productLib
  ])

  return useMemo(
    () => [
      {
        currentPath: activePath,
        onNavigate,
        options: [
          // TODO: will update this ProductSwitcher logic once we start working on BNFIR-3324, Apps will not depend on ets token for IP unified nav phase 2, it will always be in the UI
          {
            name: formatMessage('options.apps'),
            icon: <Apps />,
            menu: (
              // TODO: remove BDSProvider once we remove MuiThemeProvider in App.tsx
              <BDSProvider useBdsTheme>
                <ProductSwitcher
                  currentProductId={IP_PRODUCT_ID}
                  optionalPath={{ incidentResponsePath, impersonationProtectionPath: activePath.slice(1) }}
                  subscriptionGroups={accountSubscriptionData as ProductDataSubscriptionGroup[]}
                  ets={!hasETSInSubscriptionData && etsTokens.length > 0 ? ets : undefined}
                  incidentResponse={!hasIRInSubscriptionData ? incidentResponse : undefined}
                />
              </BDSProvider>
            )
          },
          {
            name: formatMessage('options.profile'),
            icon: <ProfileAvatar size={28}>{getInitials(userName)}</ProfileAvatar>,
            menu: (
              <ProfileMenu
                icon={<ProfileAvatar>{getInitials(userName)}</ProfileAvatar>}
                name={userName}
                email={userEmail}
                menuItems={[
                  ...(isAdmin
                    ? [
                        {
                          name: formatMessage('options.profile_menu.manage_account'),
                          onClick: (): void => {
                            window.open(`${config.bccAccountUserUrl}/#user=${userBccId}`, '_blank')
                          },
                          icon: <SupervisorAccount />,
                          endIcon: <OpenInNew />
                        }
                      ]
                    : []),
                  ...(isLicenseVisible
                    ? [
                        {
                          name: formatMessage('options.profile_menu.review_licenses'),
                          onClick: onGoToLicense,
                          icon: <RecentActors />
                        }
                      ]
                    : []),

                  {
                    name: formatMessage('options.profile_menu.log_out'),
                    onClick: onLogout,
                    icon: <ExitToApp />
                  }
                ]}
                footer={
                  serialNumber || expiration ? (
                    <>
                      {serialNumber && (
                        <Typography variant="body1" color="secondary">
                          {formatMessage('serial_number', {
                            serialNumber
                          })}
                        </Typography>
                      )}
                      {expiration && (
                        <Typography variant="body2" color="secondary">
                          {formatMessage('expiration', {
                            date: datetime.formatDate(expiration, config.DATETIME.DEFAULT_DATE_FORMAT)
                          })}
                        </Typography>
                      )}
                    </>
                  ) : null
                }
              />
            )
          }
        ],
        routes,
        logo: <FaviconEmail viewBox="0 0 56 56" />
      }
    ],
    [
      accountSubscriptionData,
      activePath,
      ets,
      etsTokens.length,
      expiration,
      formatMessage,
      getInitials,
      hasETSInSubscriptionData,
      hasIRInSubscriptionData,
      incidentResponse,
      incidentResponsePath,
      isAdmin,
      isLicenseVisible,
      onGoToLicense,
      onLogout,
      onNavigate,
      routes,
      serialNumber,
      userBccId,
      userEmail,
      userName
    ]
  )
}
